<template>
  <div>
    <div>
      <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/yeguang.png"
            alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">绑定民宿</div>
        </div>
      </div>
      <div class="inputFrom">
        <div>登录账号</div>
        <div>
          <input v-model="dataForm.LoginName"
            type="text"
            placeholder="请输入登录账号" />
        </div>
        <div>登录密码</div>
        <div>
          <input v-model="dataForm.LoginPwd"
            type="password"
            placeholder="请输入登录密码" />
        </div>
      </div>
      <div class="buttons">
        <van-button type="info"
          style="width: 80%;"
          @click="postfrom">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import { getOpenId } from "@/utils/auth";
import { HBindHotel } from "@/api/shop";
export default {
  data () {
    return {
      dataForm: {
        OpenID: '',
        LoginName: '',
        LoginPwd: '',
      },
    };
  },
  created () {
  },
  methods: {

    // 提交
    postfrom: function () {
      //身份证号验证
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      // 手机号验证
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;


      // if (this.idList.MobileTel == "") {
      //   Toast.fail("请输入联系电话");
      //   return false;
      // } else {
      //   if (!reg_tel.test(this.idList.MobileTel)) {
      //     Toast.fail("请输入正确的联系电话");
      //     return false;
      //   }
      // }
      // if (this.idList.IdNumber == "") {
      //   Toast.fail("请输入身份证号码");
      //   return false;
      // } else {
      //   if (!reg.test(this.idList.IdNumber)) {
      //     Toast.fail("身份证号码不正确");
      //     return false;
      //   }
      // }
      if (this.dataForm.LoginName == '') {
        Toast.fail("请输入登录账号");
        return false;
      }
      if (this.dataForm.LoginPwd == '') {
        Toast.fail("请输入登录密码");
        return false;
      }
      this.dataForm.OpenID = getOpenId();
      HBindHotel(this.dataForm).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
            // this.$router.push({
            //   name: "Home",
            // });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败," + res.data.msg,
          }).then(() => {

            // this.$router.go(-1);
            // on close
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.inputFrom {
  margin: 15px 8%;
  line-height: 30px;
}
.inputFrom > div {
  font-size: 18px;
  margin: 10px 0;
}
.inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>